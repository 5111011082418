var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('vs-card',[(_vm.isAuthAux('konecta.roles.add'))?_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('vs-row',{attrs:{"vs-align":"center"}},[_c('vs-col',{attrs:{"vs-w":"8"}},[_c('h3',[_vm._v(_vm._s(_vm.lang.staffRoles.title[_vm.languageSelected]))])]),_vm._v(" "),_c('vs-col',{attrs:{"vs-type":"flex","vs-justify":"flex-end","vs-w":"4"}},[_c('vs-button',{attrs:{"to":{ path: '/roles/add' }}},[_vm._v(_vm._s(_vm.lang.staffRoles.addButton[_vm.languageSelected]))])],1)],1)],1):_vm._e(),_vm._v(" "),(_vm.loaded)?_c('div',[_c('vs-table',{attrs:{"pagination":"","data":_vm.staffRolesComputed,"max-items":_vm.maxItems,"search":true,"hoverFlat":true,"no-data-text":_vm.lang.general.noDataText[_vm.languageSelected]},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
return _vm._l((data),function(tr,indextr){return _c('vs-tr',{key:indextr,attrs:{"data":tr}},[_c('vs-td',{attrs:{"data":tr.name}},[_vm._v(_vm._s(tr.name))]),_vm._v(" "),_c('vs-td',{attrs:{"data":tr.permissionsArray}},[_vm._l((tr.permissionsArray),function(permission,index){return _c('div',{key:("" + permission + index)},[(index < 3)?_c('vs-chip',{staticClass:"mr-2 mt-2",attrs:{"color":"success"}},[_c('vs-avatar',{attrs:{"color":"primary","icon":"check"}}),_vm._v(_vm._s(permission)+"\n                ")],1):_vm._e()],1)}),_vm._v(" "),(tr.permissions.length > 3)?_c('vx-tooltip',{attrs:{"text":_vm.lang.staffRoles.tableMoreTooltip.morePermissions[
                    _vm.languageSelected
                  ].replace('XXX', tr.permissions.length - 3)}},[_c('vs-chip',{staticClass:"mr-2 mt-2"},[_c('vs-avatar',{attrs:{"color":"primary","icon":"add"}}),_vm._v(_vm._s(tr.permissions.length - 3)+"\n                ")],1)],1):_vm._e()],2),_vm._v(" "),_c('vs-td',{attrs:{"data":tr.usersArray}},[_vm._l((tr.usersArray),function(user,index){return _c('div',{key:("" + user + index)},[(index <= 3)?_c('vs-chip',{staticClass:"mr-2 mt-2",attrs:{"color":"primary"}},[_c('vs-avatar',{attrs:{"color":"primary","icon":"person"}}),_vm._v(_vm._s(user)+"\n                ")],1):_vm._e()],1)}),_vm._v(" "),(tr.users.length > 3)?_c('vx-tooltip',{attrs:{"text":_vm.lang.staffRoles.tableMoreTooltip.moreUsers[
                    _vm.languageSelected
                  ].replace('XXX', tr.users.length - 3)}},[_c('vs-chip',{staticClass:"mr-2 mt-2"},[_c('vs-avatar',{attrs:{"color":"primary","icon":"add"}}),_vm._v(_vm._s(tr.users.length - 3)+"\n                ")],1)],1):_vm._e()],2),_vm._v(" "),_c('vs-td',[_vm._v("\n              "+_vm._s(_vm._f("date")(new Date(tr._updatedAt || tr._createdAt)))+"\n            ")]),_vm._v(" "),_c('vs-td',[_c('div',{staticClass:"action-buttons"},[(tr.usersArray.indexOf(_vm.session.email) > -1 && _vm.isAuthAux('konecta.roles.edit'))?_c('vx-tooltip',{attrs:{"text":_vm.lang.staffRoles.cannotSelfEdit[
                      _vm.languageSelected
                    ]}},[_c('vs-button',{staticClass:"with-space",attrs:{"disabled":"","type":"flat","icon":"create"},on:{"click":function($event){_vm.editStaffRole(tr._id)}}})],1):(_vm.isAuthAux('konecta.roles.edit'))?_c('vs-button',{staticClass:"with-space",attrs:{"type":"flat","icon":"create"},on:{"click":function($event){_vm.editStaffRole(tr._id)}}}):_vm._e(),_vm._v(" "),(tr.users.length > 0 && _vm.isAuthAux('konecta.roles.delete'))?_c('vx-tooltip',{attrs:{"text":_vm.lang.staffRoles.cannotDelete[
                      _vm.languageSelected
                    ]}},[_c('vs-button',{attrs:{"disabled":"","type":"flat","icon-pack":"feather","icon":"icon-trash"},on:{"click":function($event){_vm.deleteStaffRole(tr._id)}}})],1):(_vm.isAuthAux('konecta.roles.delete'))?_c('vs-button',{attrs:{"type":"flat","icon-pack":"feather","icon":"icon-trash"},on:{"click":function($event){_vm.deleteStaffRole(tr._id)}}}):_vm._e()],1)])],1)})}}])},[_c('template',{slot:"thead"},[_c('vs-th',{attrs:{"sortKey":"name"}},[_vm._v(_vm._s(_vm.lang.staffRoles.tableHeaders.name[_vm.languageSelected]))]),_vm._v(" "),_c('vs-th',[_vm._v(_vm._s(_vm.lang.staffRoles.tableHeaders.permissions[_vm.languageSelected]))]),_vm._v(" "),_c('vs-th',[_vm._v(_vm._s(_vm.lang.staffRoles.tableHeaders.users[_vm.languageSelected]))]),_vm._v(" "),_c('vs-th',[_vm._v(_vm._s(_vm.lang.staffRoles.tableHeaders.updated[_vm.languageSelected]))]),_vm._v(" "),_c('vs-th')],1)],2)],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }