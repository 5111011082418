<template>
  <div>
    <vs-card>
      <div slot="header" v-if="isAuthAux('konecta.roles.add')">
        <vs-row vs-align="center">
          <vs-col vs-w="8">
            <h3>{{ lang.staffRoles.title[languageSelected] }}</h3>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-end" vs-w="4">
            <vs-button :to="{ path: '/roles/add' }">{{
              lang.staffRoles.addButton[languageSelected]
            }}</vs-button>
          </vs-col>
        </vs-row>
      </div>
      <div v-if="loaded">
        <vs-table
          pagination
          :data="staffRolesComputed"
          :max-items="maxItems"
          :search="true"
          :hoverFlat="true"
          :no-data-text="lang.general.noDataText[languageSelected]"
        >
          <template slot="thead">
            <vs-th sortKey="name">{{
              lang.staffRoles.tableHeaders.name[languageSelected]
            }}</vs-th>
            <vs-th>{{
              lang.staffRoles.tableHeaders.permissions[languageSelected]
            }}</vs-th>
            <vs-th>{{
              lang.staffRoles.tableHeaders.users[languageSelected]
            }}</vs-th>
            <vs-th>{{
              lang.staffRoles.tableHeaders.updated[languageSelected]
            }}</vs-th>
            <vs-th></vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="tr.name">{{ tr.name }}</vs-td>
              <vs-td :data="tr.permissionsArray">
                <div
                  v-for="(permission, index) in tr.permissionsArray"
                  :key="`${permission}${index}`"
                >
                  <vs-chip v-if="index < 3" class="mr-2 mt-2" color="success">
                    <vs-avatar color="primary" icon="check" />{{ permission }}
                  </vs-chip>
                </div>
                <vx-tooltip
                  v-if="tr.permissions.length > 3"
                  :text="
                    lang.staffRoles.tableMoreTooltip.morePermissions[
                      languageSelected
                    ].replace('XXX', tr.permissions.length - 3)
                  "
                >
                  <vs-chip class="mr-2 mt-2">
                    <vs-avatar color="primary" icon="add" />{{
                      tr.permissions.length - 3
                    }}
                  </vs-chip>
                </vx-tooltip>
              </vs-td>
              <vs-td :data="tr.usersArray">
                <div
                  v-for="(user, index) in tr.usersArray"
                  :key="`${user}${index}`"
                >
                  <vs-chip v-if="index <= 3" class="mr-2 mt-2" color="primary">
                    <vs-avatar color="primary" icon="person" />{{ user }}
                  </vs-chip>
                </div>
                <vx-tooltip
                  v-if="tr.users.length > 3"
                  :text="
                    lang.staffRoles.tableMoreTooltip.moreUsers[
                      languageSelected
                    ].replace('XXX', tr.users.length - 3)
                  "
                >
                  <vs-chip class="mr-2 mt-2">
                    <vs-avatar color="primary" icon="add" />{{
                      tr.users.length - 3
                    }}
                  </vs-chip>
                </vx-tooltip>
              </vs-td>
              <vs-td>
                {{ new Date(tr._updatedAt || tr._createdAt) | date }}
              </vs-td>
              <vs-td>
                <div class="action-buttons">
                  <vx-tooltip
                    v-if="tr.usersArray.indexOf(session.email) > -1 && isAuthAux('konecta.roles.edit')"
                    :text="
                      lang.staffRoles.cannotSelfEdit[
                        languageSelected
                      ]
                    "
                  >
                    <vs-button
                      disabled
                      class="with-space"
                      type="flat"
                      @click="editStaffRole(tr._id)"
                      icon="create"
                    ></vs-button>
                  </vx-tooltip>
                  <vs-button
                    v-else-if="isAuthAux('konecta.roles.edit')"
                    class="with-space"
                    type="flat"
                    @click="editStaffRole(tr._id)"
                    icon="create"
                  ></vs-button>
                  <vx-tooltip
                    v-if="tr.users.length > 0 && isAuthAux('konecta.roles.delete')"
                    :text="
                      lang.staffRoles.cannotDelete[
                        languageSelected
                      ]
                    "
                  >
                    <vs-button
                      disabled
                      type="flat"
                      @click="deleteStaffRole(tr._id)"
                      icon-pack="feather"
                      icon="icon-trash"
                    ></vs-button>
                  </vx-tooltip>
                  <vs-button v-else-if="isAuthAux('konecta.roles.delete')"
                    type="flat"
                    @click="deleteStaffRole(tr._id)"
                    icon-pack="feather"
                    icon="icon-trash"
                  ></vs-button>
                </div>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </div>
    </vs-card>
  </div>
</template>

<script>
import StaffManagementService from '../../../services/staffManagement.service'

import staffManagementMixin from '@/mixins/staffManagementMixin'

import { mapState } from 'vuex'

export default {
  name: 'StaffRoles',
  mixins: [staffManagementMixin],
  data() {
    return {
      cannotDeleteErrorCode: 'cybo.groupManagement.validations.delete.hasUsers',

      isPromptActive: false,

      maxItems: 5,
      loaded: false,

      staffRoles: [],
      staffPermissions: []
    }
  },
  computed: {
    ...mapState(['languageSelected', 'lang', 'session', 'useStaffManagement']),
    staffRolesComputed() {
      return this.staffRoles.map(item => ({
        ...item,
        permissionsArray: item.permissions.map(
          element => element.label[this.languageSelected] || element.label.en
        ),
        usersArray: item.users.map(element => element.user || element.email)
      }))
    }
  },
  methods: {
    deleteStaffRole(id) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'warning',
        title: this.lang.staffRoles.deleteConfirmation.title[
          this.languageSelected
        ],
        text: this.lang.staffRoles.deleteConfirmation.content[
          this.languageSelected
        ],
        acceptText: this.lang.staffRoles.accept[this.languageSelected],
        cancelText: this.lang.staffRoles.cancel[this.languageSelected],
        accept: async () => {
          this.$vs.loading()
          try {
            await StaffManagementService.deleteStaffRole(id)
            const index = this.staffRoles.findIndex(item => item._id === id)
            if (index > -1) {
              this.staffRoles.splice(index, 1)
            }
            this.$vs.loading.close()
            this.successToast()
          } catch (ex) {
            this.$vs.loading.close()
            if (
              ex
              && ex.response
              && ex.response.data
              && ex.response.data.error
              && ex.response.data.error === this.cannotDeleteErrorCode
            ) {
              this.$vs.notify({
                title: this.lang.staffRoles.genericError.title[this.languageSelected],
                text: this.lang.staffRoles.cannotDelete[this.languageSelected],
                color: 'warning'
              })
            } else {
              console.error(ex)
            }
          }
        }
      })
    },
    editStaffRole(id) {
      this.$router.push('roles/' + id + '/edit')
    },
    errorToast() {
      this.$vs.notify({
        title: this.lang.staffRoles.genericError.title[this.languageSelected],
        text: this.lang.staffRoles.genericError.text[this.languageSelected],
        color: 'danger'
      })
    },
    successToast() {
      this.$vs.notify({
        title: this.lang.staffRoles.genericSuccess.title[this.languageSelected],
        text: this.lang.staffRoles.genericSuccess.text[this.languageSelected],
        color: 'success'
      })
    },
    async load() {
      this.$vs.loading()
      try {
        this.staffPermissions = (await StaffManagementService.getStaffPermissions()).data
        this.staffRoles = (await StaffManagementService.getStaffRoles()).data
      } catch (ex) {
        console.errror(ex)
        this.errorToast()
      }
      this.$vs.loading.close()
      this.loaded = true
    }
  },
  mounted() {
    this.load()
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss">
.derive-cell {
  display: -webkit-inline-box;
  span {
    display: inherit;
    .con-vs-checkbox {
      align-items: unset;
    }
    .multiselect {
      width: 230px;
      &--disabled {
        .multiselect__select {
          height: 42px;
        }
      }
      .multiselect__content-wrapper {
        font-size: 1rem;
        max-height: 80px !important;
      }
      .multiselect__input {
        font-size: 1rem;
      }
    }
  }
}
.action-buttons {
  display: flex;
  justify-content: flex-end;
}
.with-space {
  margin-right: 5px;
}
</style>

<style lang="scss">
.sizeSelectsSettings {
  .form-group-label {
    align-items: center;
    display: flex;
  }
  &.allSizes {
    justify-content: space-between;
    max-width: 350px;
    margin: auto;
    .con-select.selectSize {
      width: 45%;
    }
  }
}

.w-100 {
  width: 100%;
}

.w-60 {
  width: 60%;
}
</style>
